"use client";

import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { useSession } from "next-auth/react";
import { useSearchParams } from "next/navigation";
import { useState } from "react";
import { createPaymentCheckout, getFramePackPrice } from "./frame-pack-action";
import { planToStripeLookupKey, stripeLookupKeyToPlan } from "@/helpers/cost-to-frame-plan";
import { formatCurrency } from "@/helpers/currency";
import { gradientBackgroundClasses } from "@/shared/constants";
import { GradientBorder } from "@/shared/gradient-border";

interface FramePackProps {
  priceId: string;
}

function convertFramesToMinutes(frames: number, fps = 12) {
  const seconds = frames / fps;

  if (seconds < 120) {
    return `Approximately ${Math.round(seconds)} seconds of video`;
  }

  let minutes = seconds / 60;

  return `Approximately ${Math.round(minutes)} minutes of video`;
}

export function FramePack({ priceId }: FramePackProps) {
  const searchParams = useSearchParams();
  const coupon = searchParams?.get("coupon") ?? undefined;

  const [numPack, setNumPack] = useState<number>(2);

  const handleRemove = () => {
    setNumPack((num) => {
      if (num <= 1) {
        return 1;
      }

      return num - 1;
    });
  };

  const { data: session } = useSession();

  const stripeLookupKey = planToStripeLookupKey("frame_pack_20231211");
  const { frames } = stripeLookupKeyToPlan(stripeLookupKey);

  const framePackQuery = useQuery({
    queryKey: ["frame-pack", priceId],
    queryFn: () => getFramePackPrice(stripeLookupKey),
  });

  const { data } = framePackQuery;

  return (
    <>
      <GradientBorder borderSize={2}>
        <div className="flex flex-col items-center justify-center gap-2 rounded-md bg-slate-950 p-4">
          <div className="flex w-full select-none items-center justify-center gap-2 text-2xl">
            <MinusCircleIcon
              className="h-8 w-8 cursor-pointer hover:text-teal-300"
              onClick={handleRemove}
            />

            <input
              type="text"
              pattern="[0-9]*"
              className="input input-md input-bordered w-28 bg-transparent text-center text-xl"
              value={numPack}
              step={1}
              onChange={(e) => {
                let value = parseInt(e.target.value);
                if (value < 1 || isNaN(value)) {
                  value = 1;
                }
                setNumPack(value);
              }}
            />

            <PlusCircleIcon
              className="h-8 w-8 cursor-pointer hover:text-teal-300"
              onClick={() => setNumPack((num) => (num || 1) + 1)}
            />
          </div>

          <div className="flex items-center text-center text-base sm:text-lg">
            {(numPack * frames).toLocaleString()} frames for{" "}
            {data ? (
              <>
                {data.currency === "usd" ? "" : "~"}
                {formatCurrency(numPack * data.amount, data.currency)}
              </>
            ) : (
              <span className="loading loading-bars loading-xs mx-1"></span>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <div className="flex justify-start gap-2 text-xs">
              <CheckCircleIcon className={`h-4 w-4 text-slate-50`} aria-hidden="true" />
              {convertFramesToMinutes(numPack * frames)}
            </div>
            <div className="flex gap-2 text-xs">
              <CheckCircleIcon className={`h-4 w-4 text-slate-50`} aria-hidden="true" />
              Unlocks Video-to-Video
            </div>
            <div className="flex gap-2 text-xs">
              <CheckCircleIcon className={`h-4 w-4 text-slate-50`} aria-hidden="true" />
              Removes watermarks
            </div>
          </div>
          {session ? (
            <form className="pt-2">
              <input
                type="hidden"
                name="customer_email"
                value={session?.user?.email ?? undefined}
              />
              <input
                type="hidden"
                name="customer_id"
                value={session?.user.stripeCustomerId ?? undefined}
              />
              <input type="hidden" name="price_id" value={priceId} />
              <input type="hidden" name="quantity" value={numPack} />
              <input type="hidden" name="coupon" value={coupon} />

              <button
                type="submit"
                className={`${gradientBackgroundClasses} block rounded-full px-4 py-2 text-center text-base text-neutral-50`}
                formAction={createPaymentCheckout}
              >
                Purchase Pack
              </button>
            </form>
          ) : (
            <a
              href={"/sign-in?callbackUrl=/pricing"}
              className={`${gradientBackgroundClasses} block rounded-full px-4 py-2 text-center text-base text-neutral-50`}
            >
              Purchase Pack
            </a>
          )}
        </div>
      </GradientBorder>
      <p className="mt-2 max-w-sm text-center text-sm normal-case text-slate-300">
        Note: frame packs do not come with Creator and Pro benefits outside of Video-to-Video and
        removal of watermarks. The price will be automatically converted to your local currency.
      </p>
    </>
  );
}
